import { Component, Inject, Injector, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { shareReplay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ChangeLogServiceProxy, ChangeLogSelectDto } from '@shared/service-proxies/service-proxies';
import { AMPCommonModule } from '@shared/common/common.module';
import { MatIconModule } from '@angular/material/icon';
import { MarkdownModule, MarkdownService } from 'ngx-markdown'

@Component({
  selector: 'app-view-changelog-modal',
  templateUrl: './view-changelog-modal.component.html',
  standalone: true,
  imports: [
    AMPCommonModule,
    MatIconModule,
    MarkdownModule
  ],
  providers:[
    ChangeLogServiceProxy,
  ]
})
export class ViewChangeLogModalComponent extends AppComponentBase implements OnInit {
  showLoading: boolean;
  maxResult: number;
  count: number;
  listTotalCount: number;

  changeLogSubscription: Subscription;

  constructor(
      injector: Injector,
      public dialogRef: MatDialogRef<ViewChangeLogModalComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public router: Router,
      private formBuilder: FormBuilder,
      private _changeLogService: ChangeLogServiceProxy
    )
    {
      super(injector);
      this.changesList = [];
      this.maxResult = 10;
      this.count = 0;
      this.listTotalCount = 0;
      this.setForm();
    }

  changesList : ChangeLogSelectDto[]

  ngOnInit(): void {

  }

  close(){
    this.dialogRef.close();
  }

  onScroll(event) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && this.listTotalCount > this.changesList.length) {
      this.count++;
      this.setForm();
    }
  }

  setForm() {
    this.showLoading = true;
    this.changeLogSubscription = this._changeLogService.getChangeLogs(undefined, 'asc', this.count * this.maxResult, this.maxResult).pipe(shareReplay(1)).subscribe(result => {
      this.showLoading = false;
      this.listTotalCount = result.totalCount;
      this.changesList = this.changesList.concat(result.items);
    })
  }

  ngOnDestroy(){
    this.changeLogSubscription.unsubscribe();
  }
}
