<a href="/" [class]="customHrefClass" (window:resize)="onResize()">
    <img
        alt="Logo"
        [src]="defaultLogo"
        class="h-34px app-sidebar-logo-default"
    />
    <img
        alt="Logo"
        [src]="defaultSmallLogo"
        class="h-34px app-sidebar-logo-minimize m-auto"
    />
</a>
