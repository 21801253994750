<form [formGroup]="range" class="service-form">
    <mat-form-field #dayRange appearance="fill">
        <mat-label> {{ "EnterDateRange" | localize }} </mat-label>
        <mat-date-range-input
            [rangePicker]="dayRangePicker"
            [min]="minDate"
            [max]="maxDate"
        >
            <input
                matStartDate
                #dateStart
                formControlName="start"
                disabled
            />

            <input
                matEndDate
                #dateEnd
                formControlName="end"
                disabled
                (dateChange)="changeDateRange($event)"
            />
        </mat-date-range-input>
        <mat-datepicker-toggle
            matSuffix
            [for]="dayRangePicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker
            #dayRangePicker
            startView="multi-year"
            [disabled]="false"
        ></mat-date-range-picker>
    </mat-form-field>
</form>